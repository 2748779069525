<template>
  <div>
    <div class="section section-tabs">
      <div class="container">
        <div class="div-center">
            <h3><b>Frequently Asked Question</b></h3>
        </div>
        <collapse>
          <CollapseItem title="Apa itu software engineer?">
            Seorang software engineer adalah orang yang menerapkan prinsip-prinsip software untuk desain, pengembangan, 
            pemeliharaan dan pengujian dari software untuk membuat aplikasi. Mereka menggunakan bahasa pemrograman dan alat 
            pengembangan untuk membuat aplikasi berkualitas tinggi yang memenuhi kebutuhan pengguna.
          </CollapseItem>
        </collapse>
        <collapse>
          <CollapseItem title="Keterampilan apa yang saya butuhkan untuk menjadi software engineer?">
            Menjadi seorang software engineer membutuhkan dasar-dasar pemrograman yang kuat, 
            pengetahuan tentang algoritma dan struktur data, penguasaan setidaknya satu bahasa pemrograman, 
            keterampilan memecahkan masalah dan berpikir logis, serta keterampilan kerja tim dan komunikasi.
          </CollapseItem>
        </collapse>
        <collapse>
          <CollapseItem title="Bagaimana cara mempelajari software engineering?">
            Mempelajari software engineering dapat dilakukan melalui belajar mandiri, mengikuti kursus online, mengikuti kursus pelatihan, 
            membaca buku terkait dan berpartisipasi dalam proyek nyata. Kami merekomendasikan menggabungkan studi teoretis dengan praktik, 
            pembelajaran yang mendalam secara bertahap, dan praktik pengembangan aplikasi yang mendalam.
          </CollapseItem>
        </collapse>
      </div>
    </div>


  </div>
</template>

<script>

import {Collapse, CollapseItem} from '@/components'
export default {
  name: 'index',
  bodyClass: 'index-page',
  components: {
    Collapse,
    CollapseItem
  }
};
</script>
<style></style>