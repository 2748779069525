<template>
  <navbar
    position="fixed"
    type="primary"
    :transparent="transparent"
    :color-on-scroll="colorOnScroll"
    menu-classes="ml-auto"
  >
    <template>
      <router-link v-popover:popover1 class="navbar-brand" to="/">
        Genius Academy
      </router-link>
      <el-popover
        ref="popover1"
        popper-class="popover"
        placement="bottom"
        width="200"
        trigger="hover"
      >
        <div class="popover-body">
          Leading IT education brand in Southeast Asia
        </div>
      </el-popover>
    </template>
    <template slot="navbar-menu">
      <li class="nav-item">
        <a
          class="nav-link"
          rel="tooltip"
          title="Official online learning platform of Genius academy"
          data-placement="bottom"
          href="https://learn.geniusitedu.com"
          target="_blank"
        >
          <i class="now-ui-icons education_atom"></i>
          E-learning
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          rel="tooltip"
          title="Official Cloud Stable Diffusion platform of Genius academy"
          data-placement="bottom"
          href="https://paint.geniusitedu.com"
          target="_blank"
        >
          <i class="now-ui-icons design_palette"></i>
          Cloud-SD
        </a>
      </li>
      <drop-down
        tag="li"
        title="Programm"
        icon="now-ui-icons education_hat"
        class="nav-item"
      >
        <nav-link to="/classFullStackJava">
          <i class="now-ui-icons design_bullet-list-67"></i> Full Stack (JAVA+JS) Stack Engineer
        </nav-link>
        <nav-link to="/classAiPainting">
          <i class="now-ui-icons design_bullet-list-67"></i> AI Designer
        </nav-link>
      </drop-down>
      <drop-down
              tag="li"
              title="Concact Us"
              icon="now-ui-icons users_circle-08"
              class="nav-item"
      >
        <nav-link target="_blank" :href="this.whatsapp_url">
          <i class="now-ui-icons whatsapp"></i> Whatsapp
        </nav-link>
        <nav-link @click.native="modals.email = true" style="cursor: pointer;">
          <i class="now-ui-icons ui-1_email-85"></i> Email
        </nav-link>
      </drop-down>
      <li class="nav-item">
        <a
          class="nav-link"
          rel="tooltip"
          title="Like us on Facebook"
          data-placement="bottom"
          href="https://www.facebook.com/geniusacademyeducationid"
          target="_blank"
        >
          <i class="fab fa-facebook-square"></i>
          <p class="d-lg-none d-xl-none">Facebook</p>
        </a>
      </li>
      <!-- <li class="nav-item">
        <a
          class="nav-link"
          rel="tooltip"
          title="Follow us on Instagram"
          data-placement="bottom"
          href="https://www.instagram.com/CreativeTimOfficial"
          target="_blank"
        >
          <i class="fab fa-instagram"></i>
          <p class="d-lg-none d-xl-none">Instagram</p>
        </a>
      </li> -->
      <li class="nav-item">
        <a
          class="nav-link"
          rel="tooltip"
          title="Follow us on Youtube"
          data-placement="bottom"
          href="https://www.youtube.com/channel/UCoHItt65Y5Ho7jFlbiGQwdQ"
          target="_blank"
        >
          <i class="fab fa-youtube"></i>
          <p class="d-lg-none d-xl-none">Youtube</p>
        </a>
      </li>
    </template>
    <modal :show.sync="modals.email" headerClasses="justify-content-center">
      <h4 slot="header" class="title title-up color-primary">Service Email</h4>
      <p class="color-black">
        <b>Customer.service@geniusitedu.com</b>
        <br/><br/><br/>
      </p>
    </modal>
  </navbar>
</template>

<script>
import { DropDown, Navbar, NavLink, Modal, Button } from '@/components';
import { Popover } from 'element-ui';
import { any } from 'prop-types';
export default {
  name: 'main-navbar',
  props: {
    transparent: Boolean,
    colorOnScroll: Number
  },
  components: {
    DropDown,
    Navbar,
    NavLink,
    [Popover.name]: Popover,
    Modal,
    [Button.name]: Button
  },
  data() {
    return {
      modals: {
        email: false
      }
    };
  }
};
</script>

<style scoped></style>
