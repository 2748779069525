<template>
  <div>
    <div class="page-header page-header-small" filter-color="">
      <parallax
        class="page-header-image"
        style="background-image:url('img/header_frontend.png')"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <h1 class="h1-seo">Front End Full Stack Engineer</h1>
          <h3>Kuasai skill programming yang dibutuhkan industri. Mulai bangun projek portofoliomu untuk jadi Web Developer!</h3>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="div-center">
              <h3 class="text-primary" style="margin-bottom: auto;"><b>Learn the hottest skills and get the best career direction</b></h3>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="class-advantage" style="text-align:center;">
              <img src="img/recruitment.png" alt="" />
              <p>
                <b>Great demand for talents</b>
                <br />
                <small>
                  Southeast Asia is hiring more than 100,000 people a day.
                </small>
              </p>
            </div>
          </div>
          <div class="col">
            <div class="class-advantage" style="text-align:center;">
              <img src="img/salary.png" alt="" />
              <p>
                <b>High employment pay</b>
                <br />
                <small>
                  The average monthly salary in Indonesia is $19,000,000.
                </small>
              </p>
            </div>
          </div>
          <div class="col">
            <div class="class-advantage" style="text-align:center;">
              <img src="img/career.png" alt="" />
              <p>
                <b>Good career development</b>
                <br />
                <small>
                  Two-way talent development path is your choice.
                </small>
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="div-center">
              <h4 class="" style="margin-bottom: auto;">
                In the era of mobile Internet, front-end full stack engineers are needed in all walks of life
                <br />
                <br />
                <small>
                  Front-end full stack developers has a wide range of application scenarios, covering all industries.
                </small>
              </h4>
            </div>
            <div class="row">
              <div class="col industry-adventages">
                <img src="img/Financial_industryStock_trend.png" class="rounded-circle" alt="" />
                <br /><br />
                <h7>Financial industry
                  <br />
                  <br />
                  <small>The digital transformation of the financial industry needs good human-computer interaction to serve the people.</small>
                </h7>
              </div>
              <div class="col industry-adventages">
                <img src="img/Social_media_industry.png" class="rounded-circle" alt="" />
                <br /><br />
                <h7>Social media industry
                  <br />
                  <small>All social media needs top front-end full stack developers for streaming, social networking, advertising, and more.</small>
                </h7>
              </div>
              <div class="col industry-adventages">
                <img src="img/E-commerce_industry.png" class="rounded-circle" alt="" />
                <br /><br />
                <h7>E-commerce industry
                  <br />
                  <small>With the rapid development of e-commerce industry in Southeast Asia, more and more front-end full stack engineers are in demand.</small>
                </h7>
              </div>
              <div class="col industry-adventages">
                <img src="img/Public_service_industry.png" class="rounded-circle" alt="" />
                <br /><br />
                <h7>Public service industry
                  <br />
                  <small>Front-end full stack engineers are also indispensable in the public service industry, which can directly help enterprises to continuously launch services that meet the needs of The Times.</small>
                </h7>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="div-center">
              <h4 class="" style="margin-bottom: auto;">Great demand for talents, Southeast Asia is hiring more than 100,000 people a day
                <br />
                <small>
                  With the accelerated development of Internet technology, the demand for software engineers in the whole industry is increasing.
                </small>
              </h4>
            </div>
            <!-- <bar-chart :chart-data="chartData" :chart-options="chartOptions"></bar-chart> -->
            <doughnut-chart :chart-data="jobCountByCountryData" :chart-options="jobCountByCountryOptions"></doughnut-chart>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="div-center">
              <h4 class="" style="margin-bottom: auto;">Employment salary is high, stable in Southeast Asia high salary list.
                <br />
                <small>
                  High-end talent is scarce, and those who master the core technology will have a high-paying future.
                </small>
              </h4>
            </div>
            <bar-chart :chart-data="salaryGrowthData" :chart-options="salaryGrowthOptions"></bar-chart>
          </div>
          <div class="col">
            <div class="div-center">
              <h4 class="" style="margin-bottom: auto;">Good career development, Two-way talent development path is your choice.
                <br />
                <small>
                  Find the right career development direction, build core competitiveness, achieve a high starting point employment.
                </small>
              </h4>
            </div>
            <img src="img/Java_director.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="section section-tabs">
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="div-center">
              <h3 style="margin-bottom: auto;"><b>Why us?</b></h3>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="class-advantage">
              <img src="img/icons8-chatgpt-512.png" alt="" />
              <p>
                <b>AI Teaching</b>
                <br />
                <br />
                <small>
                  Coding with AI tool become so easy, Learn 4X faster than tradition IT training.
                </small>
              </p>
            </div>
          </div>
          <div class="col">
            <div class="class-advantage">
              <img src="img/multi-platform.png" alt="" />
              <p>
                <b>Front End Full Stack Development</b>
                <br />
                <small>
                  You will be a front end full stack development for Android & iOS.
                </small>
              </p>
            </div>
          </div>
          <div class="col">
            <div class="class-advantage">
              <img src="img/techlonogy.png" alt="" />
              <p>
                <b>Mainstream Advanced Technology</b>
                <br />
                <small>
                  You will learn the market mainstream advanced technology.
                </small>
              </p>
            </div>
          </div>
          <div class="col">
            <div class="class-advantage">
              <img src="img/job-interview.png" alt="" />
              <p>
                <b>Job Interview Guidance</b>
                <br />
                <br />
                <small>
                  Hand in hand guide you find job in East-South asia.
                </small>
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="class-careers">
                <h3><b>The course is optional for future careers</b></h3>
            </div>
          </div>
          <div class="col">
            <div class="class-careers">
              <table>
                <tr>
                  <td style="border-bottom:1px solid gray;border-right:1px solid gray;">
                    Front-End Developer
                  </td>
                  <td style="border-bottom:1px solid gray;">
                    IOS Software Engineer
                  </td>
                </tr>
                <tr>
                  <td style="border-right:1px solid gray;">
                    Android Software Engineer
                  </td>
                  <td>
                    Web Engineer
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="row" style="display: none;">
          <div class="col">
            <div class="div-center">
                <h3><b>The companies that have joined our class are</b></h3>
            </div>
          </div>
        </div>
        <div class="row" style="display: none;">
          <div class="col-md-2 company-logo">
                <img src="img/logo/Shopee-logo.png" alt="" />
            </div>
            <div class="col-md-2 company-logo">
                <img src="img/logo/Grab-Logo.png" alt="" />
            </div>
            <div class="col-md-2 company-logo">
                <img src="img/logo/Binance-logo.png" alt="" />
            </div>
            <div class="col-md-2 company-logo">
                <img src="img/logo/DBS-Bank-logo.png" alt="" />
            </div>
            <div class="col-md-2 company-logo">
                <img src="img/logo/Alibaba-Logo.png" alt="" />
            </div>
            <div class="col-md-2 company-logo">
                <img src="img/logo/Google-logo.png" alt="" />
            </div>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="div-center">
                <h3><b>Curriculum plan</b></h3>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="class-advantage">
              <div><n-button type="warning" round simple>1</n-button></div>
              <p>
                <b>Basic Website & Algoritma</b>
                <br />
                <small>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quae.
                </small>
              </p>
            </div>
          </div>
          <div class="col">
            <div class="class-advantage">
              <div><n-button type="warning" round simple>2</n-button></div>
              <p>
                <b>JavaScript Basic Programming</b>
                <br />
                <small>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quae.
                </small>
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="class-advantage">
              <div><n-button type="warning" round simple>3</n-button></div>
              <p>
                <b>Android & IOS Deveplop</b>
                <br />
                <small>
                  Master Android and Ios app development.
                </small>
              </p>
            </div>
          </div>
          <div class="col">
            <div class="class-advantage">
              <div><n-button type="warning" round simple>4</n-button></div>
              <p>
                <b>AI Tools</b>
                <br />
                <small>
                  Understand artificial intelligence, can apply GPT to work.
                </small>
              </p>
            </div>
          </div>
        </div>
        <n-progress :value="0"> </n-progress>
        <div class="row">
          <div class="col">
            <div class="class-advantage">
              <p>
                <b>More importantly, you can view the Front-End Development Program syllabus</b>
                <br />
              </p>
            </div>
          </div>
          <div class="col">
            <div class="class-advantage">
              <!-- <button class="btn btn-primary" link="/classFrontEndDetail">View Details</button> -->
              <a
                :href="this.whatsapp_url"
                class="btn btn-primary"
                target="_blank"
                >Get the full lesson plan</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <frequentlyQuestion></frequentlyQuestion>
    <ChatOnWhatsApp></ChatOnWhatsApp>
  </div>
</template>

<script>
import { Parallax,Button } from '@/components';
import {Progress} from '@/components';
import FrequentlyQuestion from './FrequentlyQuestion';
import ChatOnWhatsApp from './ChatOnWhatsApp';
import { Bar,Doughnut } from 'vue-chartjs';
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale
  } from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
  name: 'classFrontEnd',
  bodyClass: 'index-page',
  data() {
    return {
      chartData: {
        labels: ['Software Development Engineer ',' Backend Development Engineer ','Java Development Engineer ',' Senior Java Development Engineer ',' Internet Architect'],
        datasets: [
          {
            axis: 'y',
            label: 'Job demand',
            backgroundColor: ['#3e95cd', '#8e5ea2'],
            data: [211213, 93261, 63986, 55351, 20251],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
          },
        },
        indexAxis: 'y'
      },
      jobCountByCountryData: {
        labels: ['Indonesia','Singapore','Malaysia','Thailand','Vietnam'],
        datasets: [
          {
            label: 'Job demand',
            backgroundColor: ['#f96332', '#41B883', '#E46651', '#00D8FF', '#DD1B16'],
            data: [164253, 101261, 79986, 41351, 32251],
          },
        ],
      },
      jobCountByCountryOptions: {
        responsive: true,
        maintainAspectRatio: false
      },
      salaryGrowthData: {
        labels: ['2014','2015','2016','2017','2018','2018','2019','2020','2021','2022'],
        datasets: [
          {
            label: 'Monthly average salary changes over the years',
            backgroundColor: ['#3e95cd', '#8e5ea2'],
            data: [9500000, 10500000, 11500000, 12500000, 13500000, 14500000, 16000000, 17000000, 18000000, 19000000],
          },
        ],
      },
      salaryGrowthOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
          },
        }
      }
    };
  },
  components: {
    Parallax,
    [Progress.name]: Progress,
    [Button.name]: Button,
    FrequentlyQuestion,
    ChatOnWhatsApp,
    BarChart: {
      extends: Bar,
      props: ['chartData', 'chartOptions'],
      mounted() {
        this.renderChart(this.chartData, this.chartOptions);
      }
    },
    DoughnutChart: {
      extends: Doughnut,
      props: ['jobCountByCountryData', 'chartOptions'],
      mounted() {
        this.renderChart(this.chartData, this.chartOptions);
      }
    },
    BarChart: {
      extends: Bar,
      props: ['salaryGrowthData', 'salaryGrowthData'],
      mounted() {
        this.renderChart(this.salaryGrowthData, this.salaryGrowthData);
      }
    },
  },
  methods: {
    toDetail(){
        this.$router.push('/classFrontEndJavaDetail')
    }
  }
};
</script>
<style></style>