import Vue from 'vue';
import Router from 'vue-router';
import Index from './pages/Index.vue';
import AboutUs from './pages/AboutUs.vue';
import MainNavbar from './layout/MainNavbar.vue';
import MainFooter from './layout/MainFooter.vue';
import ClassFrontEnd from './pages/ClassFrontEnd.vue';
import ClassBackEndJava from './pages/ClassBackEndJava.vue';
import ClassFullStackJava from './pages/ClassFullStackJava.vue';
import ClassAiPainting from './pages/ClassAiPainting.vue';
import ClassFrontEndDetail from './pages/ClassFrontEndDetail.vue';
import ClassBackEndJavaDetail from './pages/ClassBackEndJavaDetail.vue';
import Doughnut from './pages/chart/Doughnut.vue';

Vue.use(Router);

export default new Router({
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/',
      name: 'index',
      components: { default: Index, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/classFrontEnd',
      name: 'classFrontEnd',
      components: { default: ClassFrontEnd, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/classFrontEndDetail',
      name: 'classFrontEndDetail',
      components: { default: ClassFrontEndDetail, header: MainNavbar, footer: MainFooter },
      props: {
        header: { },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/classBackEndJava',
      name: 'classBackEndJava',
      components: { default: ClassBackEndJava, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/classBackEndJavaDetail',
      name: 'classBackEndJavaDetail',
      components: { default: ClassBackEndJavaDetail, header: MainNavbar, footer: MainFooter },
      props: {
        header: {  },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/classFullStackJava',
      name: 'classFullStackJava',
      components: { default: ClassFullStackJava, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/ClassAiPainting',
      name: 'classAiPainting',
      components: { default: ClassAiPainting, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/aboutUs',
      name: 'aboutUs',
      components: { default: AboutUs, header: MainNavbar, footer: MainFooter },
      props: {
        header: {  },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/doughnut',
      name: 'doughnut',
      components: { default: Doughnut},
      props: {
      }
    }
  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});
