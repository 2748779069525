<template>
  <div class="base-box">
    <div class="main">
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="div-center">
              <h2 style="margin-bottom: auto;"><b>Course Outline For Back-End (JAVA) Engineer</b></h2>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="div-center">
              <h3 style="margin-bottom: auto;"><b>Four months of study is worth three years of work experience</b></h3>
            </div>
          </div>
          <div class="col">
            <div class="div-center">
              <h3 style="margin-bottom: auto;"><b>Close to the needs of The Times, to create industry-leading quality courses</b></h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'BackEndJavaDetails',
  data() {
    return {
      courses: [
        'Java编程语言',
        'Linux系统操作',
        'AI简介与GPT应用基础',
        '关系型数据库与Mysql',
        'Web开发',
        'Spring框架',
        'ORM框架（Mybatis）',
        'Spring Boot简介与优势',
        '个人云盘项目实战',
        '电商项目实战',
        '电商项目-秒杀系统实战',
        '独立项目+面试指导',
      ],
      chartData: {
        labels: ['就业率', '薪酬'],
        datasets: [
          {
            label: '数据',
            backgroundColor: ['#3e95cd', '#8e5ea2'],
            data: [80, 50000],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    };
  },
  components: {
  }
};
</script>

<style>

</style>
