<template>
<!-- 外层ul控制卡片范围 -->
<div>
    <div class="floatBtn">
        <a
        :href="this.whatsapp_url"
        class="btn btn-primary btn-lg btn-round"
        target="_blank"
        ><b><i class="now-ui-icons whatsapp"></i>  Chat on WhatsApp</b></a>
    </div>
</div>
</template>
<script>
import { Button } from '@/components';
export default {
    components: {
        [Button.name]: Button
    }
};
</script>
<style lang="scss" scoped>
  @mixin notSelect{
    -moz-user-select:none; /*火狐*/
    -webkit-user-select:none; /*webkit浏览器*/
    -ms-user-select:none; /*IE10*/
    -khtml-user-select:none; /*早期浏览器*/
    user-select:none;
  }
  @mixin not-touch {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .floatBtn {
    @include notSelect;
    @include not-touch();
    position: fixed;
    z-index: 1;
    overflow: hidden;
    width: auto;
    left: calc(100% - 50% - 125px);
    top: calc(100% - 60px);
    color: #E0933A;
    // background: #FCEBD0;
    font-size: 14px;
    height: auto;
    line-height: auto;
    text-align: center;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    &.rightBtn {
      border-radius: 20px 0 0 20px;
    }
    &.leftBtn {
      border-radius: 0 20px 20px 0;
    }
    &.moveBtn {
      border-radius: 20px;
    }
  }
</style>