<template>
  <div class="base-box">
    <div class="container">
      <h2 class="title">About Us</h2>
      <div class="row">
        <div class="col-md-6">
          <h4>Company Profile</h4>
          <p>
            We are a leading company specializing in IT training and technical consulting. Our mission is to help individuals and businesses stay competitive in the ever-changing technology landscape by providing high-quality education and training. Our courses cover a variety of topics, including software development, cybersecurity, big data and artificial intelligence.
          </p>
        </div>
        <div class="col-md-6">
          <h4>Our Vision</h4>
          <p>
            We are committed to becoming the largest and most popular IT training platform in Southeast Asia, providing high quality and practical training courses to help employees quickly acquire the skills and knowledge they need to find the job they want and realize their career development. At the same time, we are committed to breaking the geographical limitations of education, enabling more people to have access to high-quality IT training resources, and promoting the development of digital economy in Southeast Asia.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <h4>Our Team</h4>
          <p>
            Our team consists of experienced IT specialists and trainers with many years of industry experience and education background. Our faculty is committed to delivering expertise, inspiring the potential of our students and helping them achieve their career goals.
          </p>
        </div>
        <div class="col-md-6">
          <h4>Contact Us</h4>
          <p>
            If you are interested in our courses or services, please feel free to contact us. You can get more information via WhatsApp, email or visit our website. We look forward to working with you to promote the development of IT education.
          </p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { Parallax } from '@/components';

export default {
  name: "AboutUs",
  bodyClass: 'index-page'
};
</script>
