<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image:url('img/bg_home.png')"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <h1 class="title">The employment rate within 4 months of training is 98%.</h1>
          <h3>ISA payment model, now get 30% discount for contacting customer service.</h3>
        </div>
      </div>
    </div>
    <div class="section section-about-us"  style="padding-top: 0;">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">Who we are?</h2>
            <p>
              We are the leading IT education brand in Southeast Asia. 
              We use AI tools to provide high quality and practical training courses 
              to help employees quickly master the skills and knowledge they need to find the job 
              they want and realize their career development.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="main">
        <div class="container">
          <div class="row">
            <div class="col">
                <img src="img/IT_training_courses_2.png" alt="" />
            </div>
            <div class="col">
              <!--AI教学-->
              <div>
                <h4>
                  AI teaching improves efficiency by 75%.
                </h4>
                <p>
                  Through the innovative combination of traditional IT training courses and AI, 
                  students can not only learn complete course knowledge, 
                  but also have a preliminary understanding of AI principles and the application of AI tools. 
                  Following the pace of the AI era, the work efficiency of graduate students has increased to 75%, 
                  and the employment rate has reached 98% four months after graduation.
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <!--I.S.A.-->
              <div style="text-align:right;">
                <h4>
                  I.S.A. (Income Sharing Agreement) model.
                </h4>
                <p>
                  Training first, employment later pay tuition. 
                  Graduate students who find a job with an annual salary of more than {{annualSalary}} 
                  will have the tuition deducted from the salary by the bank for up to 36 months, 
                  and they will receive tuition discount by adding their contact information.
                </p>
              </div>
            </div>
            <div class="col">
                <img src="img/ISA_payment_model.png" alt="" />
            </div>
          </div>
          <div class="row" style="display: none;">
            <!--全球顶级教研团队-->
            <div class="col">
                <img src="img/teaching_team.png" alt="" style=""/>
            </div>
            <div class="col">
              <div style="">
                <h4>
                  The world's top IT teaching and research team
                </h4>
                <p>
                  We have a strong industry background of China's top IT teaching and research team, 
                  committed to providing the most advanced technology training and consulting services for various industries. 
                  Team members come from well-known enterprises in the industry, with rich practical experience and solid theoretical foundation.
                </p>
              </div>
            </div>
          </div>
          <div class="row" style="display: none;">
            <div class="col">
              <!--学员能力强-->
              <div style="text-align:right;">
                <h4>
                    Graduate students are more competent than other training institutions
                </h4>
                <p>
                  Students will have a solid programming foundation and strong problem solving skills. 
                  They will be able to master AI tools and greatly improve their work efficiency. 
                  Upon graduation, they will be at the top of the industry pyramid.
                </p>
              </div>
            </div>
            <div class="col">
                <img src="img/student_1.png" alt="" />
            </div>
          </div>
        </div>
    </div>
    <div class="section section-tabs">
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="div-center">
              <h3 style="margin-bottom: auto;"><b>Commitment and guarantee</b></h3>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="commitment" style="text-align:center;">
              <img src="img/research_team.png" alt="" />
              <p>
                <b>The world's top IT teaching and research team.</b>
                <br />
                  Our teaching and research team members are from well-known enterprises in the industry, 
                  with rich practical experience and solid theoretical foundation.
              </p>
            </div>
          </div>
          <div class="col">
            <div class="commitment" style="text-align:center;">
              <img src="img/career_guidance.png" alt="" />
              <p>
                <b>Full career guidance.</b>
                <br />
                  Hand in hand guide you find job in East-South asia, 
                  graduate students annual salary less than {{annualSalary}} within two years will not be charged tuition.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main" style="height: auto;display: none;">
      <div class="container">
        <div class="row justify-content-md-center">
          <h3>
            <p>
              With hundreds of well-known enterprises in the world to establish cooperation, 
              Promise after graduation salary is less than XXXX not accepted
            </p>
          </h3>
        </div>
        <div class="row">
          <div class="col-md-2 company-logo">
              <img src="img/logo/Google-logo.png" alt="" />
          </div>
          <div class="col-md-2 company-logo">
              <img src="img/logo/Amazon-Logo.png" alt="" />
          </div>
          <div class="col-md-2 company-logo">
              <img src="img/logo/Facebook-logo.png" alt="" />
          </div>
          <div class="col-md-2 company-logo">
              <img src="img/logo/Alibaba-Logo.png" alt="" />
          </div>
          <div class="col-md-2 company-logo">
              <img src="img/logo/Tencent-Logo.png" alt="" />
          </div>
          <div class="col-md-2 company-logo">
              <img src="img/logo/DBS-Bank-logo.png" alt="" />
          </div>
          <div class="col-md-2 company-logo">
              <img src="img/logo/bank_indesiya.webp" alt="" />
          </div>
          <div class="col-md-2 company-logo">
              <img src="img/logo/Binance-logo.png" alt="" />
          </div>
          <div class="col-md-2 company-logo">
              <img src="img/logo/okex2036.jpeg" alt="" />
          </div>
          <div class="col-md-2 company-logo">
              <img src="img/logo/Shopee-logo.png" alt="" />
          </div>
          <div class="col-md-2 company-logo">
              <img src="img/logo/Grab-Logo.png" alt="" />
          </div>
          <div class="col-md-2 company-logo">
              <img src="img/logo/Asus-Logo-1989.jpg" alt="" />
          </div>
        </div>
        <div class="row justify-content-md-center">
          <h3>
            <p>
              More than 500+ company hiring
            </p>
          </h3>
        </div>
      </div>
    </div>
    <div class="section section-class" data-background-color="write" style="padding-top: 0;">
      <div class="space-50"></div>
      <div class="container text-center">
        <div class="row">
          <div class="col">
            <router-link to="/classFullStackJava" target="_blank">
              <div class="course_link_img" :style="{backgroundImage: 'url(/img/Full_Stack_Engineer.png)' }">
              </div>
            </router-link>
          </div>
          <div class="col">
            <router-link to="/classAiPainting" target="_blank">
              <div class="course_link_img" :style="{backgroundImage: 'url(/img/AI_designer.png)' }" >
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <ChatOnWhatsApp></ChatOnWhatsApp>
  </div>
</template>

<script>
import { Parallax,Button } from '@/components';
import ChatOnWhatsApp from './ChatOnWhatsApp';
// import FrontEndBackImg from '@/assets/img/front_end_page.png'

export default {
  name: 'index',
  bodyClass: 'index-page',
  components: {
    Parallax,
    [Button.name]: Button,
    ChatOnWhatsApp
  },
  data () {
	    return {
        annualSalary: 45000000,
	    }
	}
};
</script>
<style>
.course_link_img{
		width: 100%;
    height:300px;
    background-position: center center;
    background-repeat:  no-repeat;
    background-size: contain;
	}
.course-h3{
  padding-top: 120px;
}

</style>