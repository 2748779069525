<template>
  <div>
    <div class="page-header page-header-small" filter-color="">
      <parallax
        class="page-header-image"
        style="background-image:url('img/header_ai_designer.png')"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
            <h1 class="h1-seo"><b>AI Designer</b></h1>
            <h3>In the era of AI, it is not AI that will eliminate you, but peers who will use AI.</h3>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="div-center">
              <h3 class="text-primary" style="margin-bottom: auto;"><b>Everyone must learn AI painting design class</b></h3>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="class-advantage" style="text-align:center;">
              <img src="img/recruitment.png" alt="" />
              <p>
                <b>The first AI designer</b>
                <br />
                <small>
                  To be the first AI designer in Southeast Asia.
                </small>
              </p>
            </div>
          </div>
          <div class="col">
            <div class="class-advantage" style="text-align:center;">
              <img src="img/salary.png" alt="" />
              <p>
                <b>High employment pay</b>
                <br />
                <small>
                  Outperform your peers for higher pay.
                </small>
              </p>
            </div>
          </div>
          <div class="col">
            <div class="class-advantage" style="text-align:center;">
              <img src="img/career.png" alt="" />
              <p>
                <b>Good career development</b>
                <br />
                <small>
                  AI painting industry development prospects are very broad.
                </small>
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="div-center">
              <h4 class="" style="margin-bottom: auto;">AI painting will have a profound impact on designers' careers
                <br />
                <br />
                <small>
                  The application scenarios of AI painting are very wide, covering all fields.
                </small>
              </h4>
            </div>
            <div class="row">
              <div class="col industry-adventages">
                <img src="img/art_creative.png" class="rounded-circle" alt="" />
                <br /><br />
                <h7>Artistic Creation
                  <br />
                  <br />
                  <small>
                    AI can assist artists in creating new works or provide inspiration for them. For instance, artists can use AI to generate sketches or concepts for new paintings, sculptures, or other visual art pieces.
                  </small>
                </h7>
              </div>
              <div class="col industry-adventages">
                <img src="img/gaming_props.jpg" class="rounded-circle" alt="" />
                <br /><br />
                <h7>Entertainment and Gaming
                  <br />
                  <small>
                    AI painting can be used to generate game backgrounds, character designs, or other visual elements. Additionally, it can be used in film and animation production to create complex scenes or characters.
                  </small>
                </h7>
              </div>
              <div class="col industry-adventages">
                <img src="img/shopping_cart.png" class="rounded-circle" alt="" />
                <br /><br />
                <h7>E-commerce and advertising
                  <br />
                  <small>
                    E-commerce brands can use AI painting to create unique advertising material, KV, AI models, etc., or customize content for a specific market or audience.
                  </small>
                </h7>
              </div>
              <div class="col industry-adventages">
                <img src="img/building.png" class="rounded-circle" alt="" />
                <br /><br />
                <h7>Interior Design and Architecture
                  <br />
                  <small>
                    AI can assist designers in creating new interior design schemes or provide architects with suggestions and concepts for architectural structures.
                  </small>
                </h7>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="div-center">
              <h4 class="" style="margin-bottom: auto;">The commercial application of AI painting will grow rapidly in the next few years
                <br />
                <small>
                  It is expected that by 2030, the market penetration of AI painting in the design field will exceed 95%
                </small>
              </h4>
            </div>
            <line-chart :chart-data="aiPaintingDevelopData" :chart-options="aiPaintingDevelopOptions" style="height: 300px;"></line-chart>
          </div>
        </div>
      </div>
    </div>
    <div class="section section-tabs">
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="div-center">
              <h3 class="text-primary" style="margin-bottom: auto;"><b>Why us?</b></h3>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="class-advantage">
              <img src="img/artificial-intelligence.png" alt="" />
              <p>
                <b>AIGC technology</b>
                <br />
                <br />
                <small>
                  Advance the era of intelligence, master AIGC cutting-edge technology.
                </small>
              </p>
            </div>
          </div>
          <div class="col">
            <div class="class-advantage">
              <img src="img/content-management.png" alt="" />
              <p>
                <b>AI industry expert team</b>
                <br />
                <br />
                <small>
                  Top teaching and research team, designed by AI industry experts.
                </small>
              </p>
            </div>
          </div>
          <div class="col">
            <div class="class-advantage">
              <img src="img/idea.png" alt="" />
              <p>
                <b>Art and technology</b>
                <br />
                <br />
                <small>
                  Set AI painting complete, unlock tool toolbox, efficient release creativity.
                </small>
              </p>
            </div>
          </div>
          <div class="col">
            <div class="class-advantage">
              <img src="img/cloud-computing.png" alt="" />
              <p>
                <b>Cloud AI painting tool</b>
                <br />
                <br />
                <small>
                  Self-developed cloud AI painting tools, one-stop solution software.
                </small>
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="class-careers">
                <h3><b>The course is optional for future careers</b></h3>
            </div>
          </div>
          <div class="col">
            <div class="class-careers">
              <table>
                <tr>
                  <td style="border-bottom:1px solid gray;border-right:1px solid gray;">
                    Graphic designer
                  </td>
                  <td style="border-bottom:1px solid gray;">
                    AI designer
                  </td>
                </tr>
                <tr>
                  <td style="border-right:1px solid gray;">
                    Illustrator
                  </td>
                  <td>
                    Prompt Engineer
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="row" style="display: none;">
          <div class="col">
            <div class="div-center">
                <h3><b>The companies that have joined our class are</b></h3>
            </div>
          </div>
        </div>
        <div class="row"  style="display: none;">
          <div class="col-md-2 company-logo">
                <img src="img/logo/Shopee-logo.png" alt="" />
            </div>
            <div class="col-md-2 company-logo">
                <img src="img/logo/Grab-Logo.png" alt="" />
            </div>
            <div class="col-md-2 company-logo">
                <img src="img/logo/Binance-logo.png" alt="" />
            </div>
            <div class="col-md-2 company-logo">
                <img src="img/logo/DBS-Bank-logo.png" alt="" />
            </div>
            <div class="col-md-2 company-logo">
                <img src="img/logo/Alibaba-Logo.png" alt="" />
            </div>
            <div class="col-md-2 company-logo">
                <img src="img/logo/Google-logo.png" alt="" />
            </div>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="div-center">
                <h3><b>Curriculum plan</b></h3>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="class-advantage">
              <div><n-button type="warning" round simple>1</n-button></div>
              <p>
                <b>AI painting a variety of advanced applications and cash?</b>
                <br />
                <small>
                  A preliminary understanding of AI painting various application scenarios, as well as ways to make money.
                </small>
              </p>
            </div>
          </div>
          <div class="col">
            <div class="class-advantage">
              <div><n-button type="warning" round simple>2</n-button></div>
              <p>
                <b>AI painting principle & Stable Diffusion</b>
                <br />
                <small>
                  Learn the basic principles of AI painting and master the basic skills of Stable diffusion, which is the best AI painting tool at present.
                </small>
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="class-advantage">
              <div><n-button type="warning" round simple>3</n-button></div>
              <p>
                <b>Various models & ControlNet</b>
                <br />
                <small>
                  Learn a variety of classic models and control the image output with ControlNet.
                </small>
              </p>
            </div>
          </div>
          <div class="col">
            <div class="class-advantage">
              <div><n-button type="warning" round simple>4</n-button></div>
              <p>
                <b>AI painting commercial application real combat.</b>
                <br />
                <small>
                  Real combat AI illustration, AI poster, AI clothing model, AI interior design and other classic cases.
                </small>
              </p>
            </div>
          </div>
        </div>
        <n-progress :value="0"> </n-progress>
        <div class="row">
          <div class="col">
            <div class="class-advantage">
              <p>
                <b>More importantly, you can view the AI designer syllabus</b>
                <br />
              </p>
            </div>
          </div>
          <div class="col">
            <div class="class-advantage">
              <!-- <button class="btn btn-primary" @click="toDetail">View Details</button> -->
              <a
                :href="this.whatsapp_url"
                class="btn btn-primary"
                target="_blank"
                >Get the full lesson plan</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <frequentlyQuestion></frequentlyQuestion>
    <ChatOnWhatsApp></ChatOnWhatsApp>
  </div>
</template>

<script>
import { Parallax,Button } from '@/components';
import {Progress} from '@/components';
import FrequentlyQuestion from './FrequentlyQuestionByAiPainting';
import ChatOnWhatsApp from './ChatOnWhatsApp';
import { Bar,Doughnut,Line } from 'vue-chartjs';
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement
  } from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement);

export default {
  name: 'index',
  bodyClass: 'index-page',
  data() {
    return {
      aiPaintingDevelopData: {
        labels: ['2023','2024','2025','2026','2027','2028','2029','2030'],
        datasets: [
          {
            label: 'Market penetration',
            borderColor: "#f96332",
            backgroundColor: ['#f96332'],
            data: [1, 20, 45, 70, 85, 90, 95, 98],
            tension: 0.4,
            pointRadius: 0
          },
        ]
      },
      aiPaintingDevelopOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            ticks: {
              color: "#b6baca",
            },
            grid: {
              drawTicks: false,
            },
            border: {
              display: false,
            },
          },
          x: {
            ticks: {
              color: "#b6baca",
            },
            grid: {
              display: false,
            },
            border: {
              display: false,
            },
          },
        }
      },
      aiPaintingDevelopHeight: {
        type: Number,
        default: 600
      }
    };
  },
  components: {
    Parallax,
    [Progress.name]: Progress,
    [Button.name]: Button,
    FrequentlyQuestion,
    ChatOnWhatsApp,
    LineChart: {
      extends: Line,
      props: ['aiPaintingDevelopData', 'aiPaintingDevelopOptions'],
      mounted() {
        this.renderChart(this.chartData, this.chartOptions);
      }
    },
  },
  methods: {
    toDetail(){
        this.$router.push('/classBackEndJavaDetail')
    }
  }
};
</script>
<style></style>