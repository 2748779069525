<template>
  <footer
    class="footer"
    :class="{ [`footer-${type}`]: type }"
    :data-background-color="backgroundColor"
  >
    <div class="container">
      <nav>
        <ul>
          <li>
            <router-link :to="{path:'/'}">Genius Academy</router-link>
          </li>
          <li>
            <router-link :to="{path:'/aboutUs'}">About Us</router-link>
          </li>
          <li>
            <a class="nav-link" rel="tooltip" 
              title="Official online learning platform of Genius academy" 
              href="https://learn.geniusitedu.com"
              target="_blank">
              E-learning
            </a>
          </li>
        </ul>
      </nav>
      <div class="copyright">
        &copy; {{ year }}, Designed by Genius Academy.
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  props: {
    backgroundColor: String,
    type: String
  },
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style></style>
