<template>
  <div>
    <div class="section section-tabs">
      <div class="container">
        <div class="div-center">
            <h3><b>Frequently Asked Question</b></h3>
        </div>
        <collapse>
          <CollapseItem title="What exactly does the course cover?">
            This course starts with the basics of AI painting and progresses to advanced techniques. 
            Basic design skills are not required, but a basic understanding of AI and design can help. 
            Please contact customer service for detailed course outline.
          </CollapseItem>
        </collapse>
        <collapse>
          <CollapseItem title="What are the career opportunities for students after completing this program?">
            Upon completion of this course, students will have the ability to apply AI painting in almost any industry. 
            Whether you work full time or part time.
          </CollapseItem>
        </collapse>
        <collapse>
          <CollapseItem title="How much does the course cost? Do you have a refund policy if a student is not satisfied?">
            We provide our students with leading courses and services, the specific fees for each course may vary slightly, 
            please contact our customer service for the latest course quotation and refund policy.
          </CollapseItem>
        </collapse>
      </div>
    </div>


  </div>
</template>

<script>

import {Collapse, CollapseItem} from '@/components'
export default {
  name: 'index',
  bodyClass: 'index-page',
  components: {
    Collapse,
    CollapseItem
  }
};
</script>
<style></style>